import "@/scss/main.scss";
import { createRouter, createWebHistory } from 'vue-router';

const routes = [
	{
		path: '/',
		component: () => import('../views/HomePage.vue'),
		meta: { title: 'Home', },
	},
	{
		path: '/contact',
		component: () => import('../views/ContactPage.vue'),
		meta: { title: 'Inquiry', },
	},
	{
		path: '/eBrake',
		component: () => import('../views/eBrake/EBrakePage.vue'),
		meta: { title: 'E-Brake', },
	},
	{
		path: '/eBrake/50-130T',
		component: () => import('../views/eBrake/50-130T.vue'),
		meta: { title: 'E-Brake 50-130T Ultra', },
	},
	{
		path: '/eBrake/35-40T',
		component: () => import('../views/eBrake/35-40T.vue'),
		meta: { title: 'E-Brake 35-40T Premium Ergonomic', },
	},
	{
		path: '/eBrake/35-130T',
		component: () => import('../views/eBrake/35-130T.vue'),
		meta: { title: 'E-Brake 35-130T Premium', },
	},
	{
		path: '/eBrake/300T',
		component: () => import('../views/eBrake/300T.vue'),
		meta: { title: 'E-Brake 300T Ultra Dual Drive', },
	},
	{
		path: '/eBrake/C200TToolMate',
		component: () => import('../views/eBrake/C200TToolMate.vue'),
		meta: { title: 'E-Brake C 200T Tool Mate', },
	},
	{
		path: '/eBrake/160-200T',
		component: () => import('../views/eBrake/160-200T.vue'),
		meta: { title: 'E-Brake 160-200T Ultra', },
	},
	{
		path: '/eBrake/MiniCell',
		component: () => import('../views/eBrake/MiniCell.vue'),
		meta: { title: 'E-Brake Mini-Cell', },
	},
	{
		path: '/hBrake',
		component: () => import('../views/hBrake/HBrakePage.vue'),
		meta: { title: 'H-Brake', },
	},
	{
		path: '/hBrake/110-170T',
		component: () => import('../views/hBrake/110-170T.vue'),
		meta: { title: 'H-Brake Hybrid 110-170T Ultra Premium', },
	},
	{
		path: '/hBrake/110T-1600',
		component: () => import('../views/hBrake/110T-1600.vue'),
		meta: { title: 'H-Brake Hybrid 110T-1600 Premium', },
	},
	{
		path: '/rBrake/130T-3100',
		component: () => import('../views/rBrake/130T-3100.vue'),
		meta: { title: 'R-Brake 130T-3100', },
	},
	{
		path: '/RoboMate',
		component: () => import('../views/RoboMate.vue'),
		meta: { title: 'Robo Mate', },
	},
	{
		path: '/settings',
		component: () => import('../views/SettingsPage.vue'),
		meta: { title: 'Settings', },
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior() {
		return { top: 0 }
	},
});

router.beforeEach(() => {
	// Mimic service worker updating on navigation for SPA
	if ('serviceWorker' in navigator) {
		navigator.serviceWorker.ready.then(worker => worker.update());
	}
});

router.afterEach((to) => {
	document.title = (to.meta.title ? to.meta.title + ' | ' : '') + 'Safan Darley';
});

export default router
